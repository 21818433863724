.privacypolicynavbar {
    display: block;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    cursor: pointer;
    padding: 0px 20px;
}

.background {
    background: #c55a5a;
    background-blend-mode: darken;
    background-size: cover;
}

.nav-list {
    width: 100%;
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: 180px;
    /* background: white; */
    padding: 9px;
}

.nav-list li {
    list-style: none;
    padding: 18px 19px;
    font-weight: 500;
    color: #ffffff !important;
    font-size: 19px;
    font-family: 'Times New Roman';
}

.wrapper {
    margin-top: 15px;
}

.container {
    width: 95%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

body {
    /* font-family: 'Times New Roman', Times, serif; */
    font-family: 'Roboto', sans-serif;
}

h2 {
    font-size: 21px;
    font-weight: bold;
    color: #505458;
    margin: 10px 0;
    line-height: 35px;
}

.pheading {
    font-weight: bold;
}

p {
    color: #000;
    text-align: justify !important;
    line-height: 1.6;
    margin: 0 0 10px;
    font-size: 14px;
}