.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-wrapper {
  background-color: #ebf1f6;
}

.card-body {
  text-align: initial;
}

.card-custom {
  text-align: initial;
}

.container-fluid {
  position: relative;
  max-width: 1500px !important;
  margin: 0 auto;
}

.fw-semibold {
  font-style: revert;
  font-size: inherit;
}

.table {
  margin-bottom: 0px
}

.mb-0 {
  text-align: initial;
}

.react-datepicker-popper {
  z-index: 1;
}

.py-2 {
  text-align: initial;
}

.py-3 {
  text-align: initial;
}

.btn-outline-danger {
  --bs-btn-color: #B71C1C;
  --bs-btn-border-color: #B71C1C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #B71C1C;
  --bs-btn-hover-border-color: #B71C1C;
  --bs-btn-focus-shadow-rgb: 250, 137, 107;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #B71C1C;
  --bs-btn-active-border-color: #B71C1C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #B71C1C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #B71C1C;
  --bs-gradient: none;
}

.text-danger {
  font-weight: bold;
  color: rgba(255, 0, 0, 0.842) !important;
}

.app-header .navbar {
  min-height: 50px !important;
  height: 40px !important;
}

.sidebar-nav {
  overflow-y: unset;
  padding: 1px !important;
}

.sidebar-link {
  FONT-WEIGHT: 400;
  color: #B71C1C;
  display: flex;
  font-size: 14px;
  white-space: nowrap;
  align-items: center;
  line-height: 25px;
  position: inherit;
  margin: 0 0 2px;
  padding: 10px;
  border-radius: 7px;
  gap: 15px;
  text-decoration: none;
  font-weight: 400;
}

.css-1ygcj2i-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 545 !important;
  font-size: 0.80rem;
  line-height: 1.4rem !important;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 7px !important;
  color: rgba(0, 0, 0, 0.87);
}

.nav-small-cap {
  margin-top: 24px;
  color: #2a3547;
  font-size: 12px;
  font-weight: 700;
  padding: 3px 10px;
  line-height: 26px;
  text-transform: uppercase;
  text-align: left;
}

.bg-light-info {
  background: linear-gradient(45deg, #3c9526, transparent);
}

.bg-light-success {
  background: linear-gradient(45deg, #007ab1, transparent);
}

.bg-light-warning {
  background: linear-gradient(45deg, #fea1a1, transparent);
}

.bg-light-danger {
  background: linear-gradient(45deg, #917dfc, transparent);
}

.scroll-sidebar {
  /* width: 270px; */
  background-color: #fff;
  position: absolute;
  transition: .2s ease-in;
  height: 90%;
  z-index: 11;
  border-right: 1px solid #e5eaef;
  padding: 0 24px !important;
  position: fixed;
}

.MuiTablePagination-selectLabel {
  margin-top: auto !important;
}

.MuiTablePagination-displayedRows {
  margin-top: auto !important;
}

.card-footer {
  text-align: end;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: #B71C1C !important;
}

.fa-plus-square {
  color: #B71C1C !important;
}

.fa-minus-square {
  color: #B71C1C !important;
}

.readonly {
  background: aliceblue !important;
}

.css-hyum1k-MuiToolbar-root {
  min-height: 40px !important;
}

.css-15wwp11-MuiTableHead-root {
  display: table-header-group;
  position: sticky !important;
}

#main-wrapper[data-layout=horizontal] .app-header .navbar .navbar-nav .nav-item .nav-link {
  height: 70px;
  line-height: 0px !important;

}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: inherit !important;
}

h3 {
  font-size: 2em;
  color: #000 !important;
}

.swal2-warning {
  border-color: #B71C1C !important;
  color: #B71C1C !important;
}

hr {
  margin: 0.2rem 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
  margin: auto;
}

.navbar-nav .dropdown-menu.content-dd {
  width: 200px;
}

.w3layouts-main {
  background: linear-gradient(45deg, #B71C1C, transparent)
}

tr {
  border-color: inherit;
  border-style: solid;
  border-width: thin !important;
}

h2 {
  font-size: 26em;
  color: #fff;
  line-height: 1.3em;
  letter-spacing: 0px !important;
}

.css-1bigob2 {
  z-index: auto !important;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: rgb(0 0 0 / 0%) !important;
}

.css-qfso29-MuiTypography-root-MuiDialogContentText-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: small !important;
  line-height: initial;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
}

.btn-outline-primary {
  --bs-btn-color: black;
  --bs-btn-border-color: black;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 93, 135, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: black;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: black;
  --bs-btn-border-color: black;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 42, 53, 71;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: black;
  --bs-gradient: none;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: black;
  --bs-btn-border-color: black;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 117, 153, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: black;
  --bs-btn-disabled-border-color: black;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #B71C1C;
  --bs-btn-border-color: #B71C1C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #B71C1C;
  --bs-btn-hover-border-color: #B71C1C;
  --bs-btn-focus-shadow-rgb: 251, 155, 129;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #B71C1C;
  --bs-btn-active-border-color: #B71C1C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #B71C1C;
  --bs-btn-disabled-border-color: #B71C1C;
}

.sidebar-nav ul .sidebar-item>.sidebar-link.active {
  background-color: #B71C1C;
  color: #fff;
}

.sidebar-link {
  color: #B71C1C;
}

.sidebar-link:hover {
  color: #B71C1C !important;
}

.sidebar-nav ul .sidebar-item>.sidebar-link:hover {
  background-color: rgba(119, 69, 80, 0.11) !important;
  color: #B71C1C;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link.active {
  color: #B71C1C !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #B71C1C !important;
  color: #fff;
  font-size: 1em;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #0e0e0e !important;
  color: #fff;
  font-size: 1em;
}

.ti-menu-2 {
  --bs-text-opacity: 1;
  color: #B71C1C !important;
}

.sidebar-nav ul .sidebar-item .sidebar-link:hover.has-arrow::after {
  border-color: #B71C1C;
}

/* #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-fluid, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-lg, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-md, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-sm, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-xl, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-xxl {
  padding-top: 106px!important;
} */

#main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .left-sidebar {
  position: fixed;
  padding-top: 60px;
  padding-bottom: 10px;
}


.body-wrapper>.container-fluid,
#main-wrapper[data-layout=horizontal] .body-wrapper>.container-lg,
#main-wrapper[data-layout=horizontal] .body-wrapper>.container-md,
#main-wrapper[data-layout=horizontal] .body-wrapper>.container-sm,
#main-wrapper[data-layout=horizontal] .body-wrapper>.container-xl,
#main-wrapper[data-layout=horizontal] .body-wrapper>.container-xxl,
#main-wrapper[data-layout=horizontal] .navbar,
#main-wrapper[data-layout=horizontal] .scroll-sidebar {
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
}

.left-sidebar .scroll-sidebar {
  overflow-y: auto;
  padding: 0 24px;
  height: calc(90vh - 50px);
  border-radius: 7px;
}

.app-header .navbar {
  min-height: 55px !important;
  height: 30px !important;
}

.css-1ex1afd-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 10px !important;
  color: rgba(0, 0, 0, 0.87);
}

.sidebar-nav ul .sidebar-item.selected>.sidebar-link,
.sidebar-nav ul .sidebar-item.selected>.sidebar-link.active,
.sidebar-nav ul .sidebar-item>.sidebar-link.active {
  background-color: #B71C1C;
  color: #fff;
}


.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: black !important;
}

.react-datepicker__year-option .react-datepicker__navigation {
  width: 0;
  border: 0.45rem solid transparent;
  height: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: black !important;
}

/* //Add Live----// */
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-size: 1rem;
  --bs-nav-link-font-weight: 400;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 1% !important;
  margin-bottom: 0;
  list-style: none;
}

.body-wrapper>.container-fluid,
#main-wrapper[data-layout=horizontal] .body-wrapper>.container-lg,
#main-wrapper[data-layout=horizontal] .body-wrapper>.container-md,
#main-wrapper[data-layout=horizontal] .body-wrapper>.container-sm,
#main-wrapper[data-layout=horizontal] .body-wrapper>.container-xl,
#main-wrapper[data-layout=horizontal] .body-wrapper>.container-xxl,
#main-wrapper[data-layout=horizontal] .navbar,
#main-wrapper[data-layout=horizontal] .scroll-sidebar {
  position: relative;
  max-width: 1500px;
  margin: 0 auto
}

.css-li77g6 {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  z-index: 0 !important;
}

.css-wse3dj-MuiPaper-root-MuiDialog-paper {
  background-color: #fff0 !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 0%), 0px 24px 38px 3px rgb(0 0 0 / 0%), 0px 9px 46px 8px rgb(0 0 0 / 0%) !important;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 600px;
  width: 400%;
  max-width: 400px !important;
}

.css-919eu4 {
  position: fixed;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  inset: 0px;
  background-color: rgb(0 0 0 / 0%) !important;
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

/* //-----------------------// */